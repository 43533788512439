import {
    Button,
    Card,
    CardContent,
    Grid,
    Icon,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import React from "react";
import * as clipboard from "clipboard-polyfill";

const useStyles = () => ({
    root: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 32,
        maxWidth: 600
    },
    output: {
        padding: 16
    }
});

class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "Dr Flat Eric",
            title: "Senior Cookie Cutter",
            phone: "0411 222 333",
            email: "flat.eric@bilue.com.au",
            office: "sydney",
            clipboardHtml: ""
        }
    }

    onSelectChanged(input) {
        this.setState({
            office: input.value
        })
    }
    onInputChanged(input) {
        switch(input.id) {
            case "name":
                this.setState({
                    name: input.value
                })
                break
            case "phone":
                this.setState({
                    phone: input.value
                })
                break
            case "title":
                this.setState({
                    title: input.value
                })
                break
            case "email":
                this.setState({
                    email: input.value
                })
                break
            default:
                break
        }
    }

    async copyResultToClipboard() {
        const item = new clipboard.ClipboardItem({
            "text/html": new Blob(
              [document.getElementById("result").innerHTML],
              { type: "text/html" }
            )
          })

        await clipboard.write([item]);
    };

    render() {
        const {classes} = this.props;
        return (
            <Card className={classes.root}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                id="name"
                                label="Your name" 
                                variant="outlined"
                                onChange={(event) => this.onInputChanged(event.target)} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                id="title"
                                label="Your job title" 
                                variant="outlined"
                                onChange={(event) => this.onInputChanged(event.target)} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                id="phone"
                                label="Your phone number" 
                                variant="outlined"
                                onChange={(event) => this.onInputChanged(event.target)} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                id="email"
                                label="Your email address" 
                                variant="outlined" 
                                onChange={(event) => this.onInputChanged(event.target)} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel id="select-label-address">Select Your Office</InputLabel>
                            <Select
                                fullWidth
                                labelId="select-label-address"
                                id="office"
                                variant="outlined"
                                value={this.state.office}
                                label="Office"
                                onChange={(event) => this.onSelectChanged(event.target)} >
                                    <MenuItem value="sydney">Sydney</MenuItem>
                                    <MenuItem value="melbourne">Melbourne</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="p" variant="p" align="left" color="textPrimary" gutterBottom>
                                Copy the signature into clipboard:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper
                                id="result"
                                className={classes.output}
                                elevation={0}
                                variant="outlined"
                                rounded>
                                {/* eslint-disable react/style-prop-object */}
                                {/* eslint-disable react/jsx-no-target-blank */}
                                <table style={{fontSize:"16px",color:"rgb(89,98,120)", fontFamily:"proxima-nova,sans-serif", letterSpacing:"0.25px"}}>
                                    <tbody>
                                        <tr>
                                            <td style={{verticalAlign:'top', width: '90px'}}><a href="http://bilue.com.au/" style={{color:"rgb(0,161,255)", textDecoration: "none"}} target="_blank"><img src="https://docs.bilue.com.au/wp-content/uploads/2019/03/eSig-Bilue.gif" alt="Bilue" width="90" height="42" style={{borderStyle:"none", height:"auto", width:"88px"}} /></a></td>
                                            <td width="20"></td>
                                            <td style={{fontFamily:"Helvetica,Arial,sans-serif", fontSize:"12px", lineHeight:"18px"}}>
                                                <span style={{fontWeight:"600"}}>{this.state.name}</span><br/>
                                                {this.state.title}<br/>
                                                {this.state.phone}<span style={{color:"rgb(204,204,204)"}}>&nbsp;|&nbsp;</span>
                                                <a href={"mailto:" + this.state.email} target="_blank"><span>{this.state.email}</span></a><br/>
                                                {this.state.office === "sydney"
                                                    ? `Level 4, 4-6 Bligh Street, Sydney 2000` 
                                                    : `Level 7, 180 Flinders St Melbourne VIC 3000`}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p style={{fontFamily: "Helvetica, Arial, sans-serif", color: "#9399a7", fontSize: "11px", lineHeight: "18px", marginTop: "20px"}}>This e-mail, and any attachment, is confidential. If you are not the intended recipient, please delete it from your system, do not use or disclose the information in any way, and notify the sender immediately. Any views expressed in this message are those of the individual sender and may not be the views of Bilue PTY LTD, unless specifically stated. No warranty is made that the e-mail or attachment (s) are free from computer viruses or other defects.</p>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                endIcon={<Icon>content_copy</Icon>}
                                onClick={() => this.copyResultToClipboard()}
                            >
                                Copy
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
}

export default withStyles(useStyles)(Home)