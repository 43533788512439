import './App.css';
import { AppBar, CssBaseline, Toolbar, ThemeProvider, createMuiTheme } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './Home'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#03a1ff',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <AppBar position="relative">
            <Toolbar>
              <h2>Bilue Signature Generator</h2>
            </Toolbar>
        </AppBar>
        <Switch>
            <Route path="/">
                <Home />
            </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
